export enum CHART_TYPE {
  BAR = 'bar',
  DOUGHNUT = 'doughnut',
  HORIZONTALBAR = 'horizontalbar',
  FUNNEL = 'funnel',
  PIPE = 'pipe'
}

export enum TREND_TYPE {
  DISTRIBUTION = 'distribution',
  FUNNEL = 'funnel',
  EVOLUTION = 'evolution',
  RATIO = 'ratio'
}

export enum EXPLORER_TYPE {
  BAR = CHART_TYPE.BAR,
  DOUGHNUT = CHART_TYPE.DOUGHNUT,
  LIST = 'list',
  KPI = 'kpi'
}

export const EXPLORER_TYPES_ICON: { [key in EXPLORER_TYPE]: string } = {
  [EXPLORER_TYPE.BAR]: 'bar',
  [EXPLORER_TYPE.DOUGHNUT]: 'doughnut',
  [EXPLORER_TYPE.LIST]: 'list',
  [EXPLORER_TYPE.KPI]: 'numbers'
}

export const MAX_MEASURE_BY_EXPLORER_TYPE: {
  [type in EXPLORER_TYPE]: number
} = {
  [EXPLORER_TYPE.LIST]: 1,
  [EXPLORER_TYPE.KPI]: 1,
  [EXPLORER_TYPE.BAR]: 2,
  [EXPLORER_TYPE.DOUGHNUT]: 2
}

export const GROUPED_EXPLORER_TYPES: {
  types: EXPLORER_TYPE[]
  labelKey: string
}[] = [
  {
    labelKey: 'explore.type.list',
    types: [EXPLORER_TYPE.LIST]
  },
  {
    labelKey: 'explore.type.kpi',
    types: [EXPLORER_TYPE.KPI]
  },
  {
    labelKey: 'explore.type.chart',
    types: [EXPLORER_TYPE.BAR, EXPLORER_TYPE.DOUGHNUT]
  }
]
